import React from 'react';
import { Box, Button, Container, Grid, IconButton, Input } from '@mui/joy';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CloseIcon from '@mui/icons-material/Close';

import { useNavigate } from 'react-router-dom';
export default function PuppetHome() {

  const navigate = useNavigate();
  const [jobQueueId, setJobQueueId] = React.useState<string>('');
  const jobQueueIds = JSON.parse(localStorage.getItem('jobQueueIds') || "[]") as string[];

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.level1' }}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ChecklistRtlIcon sx={{fontSize: 48}}/>
          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                navigate(`/puppet/${jobQueueId}`);
              }}
            >
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Input required placeholder='Job Queue ID' value={jobQueueId} onChange={(e) => setJobQueueId(e.target.value)}/>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant='solid'
                  sx={{ mt: 3, mb: 2, width: '100%' }}
                >
                  Get Jobs
                </Button>
            </form>
          </Box>
        </Box>
      </Container>
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2}>
          {
            jobQueueIds.map((jobQueueId, index) => (
              <Grid xs={4} md={3} key={index} sx={{display: 'flex'}}>
                <Button variant='plain' sx={{ flex: 1 }} onClick={() => navigate(`/puppet/${jobQueueId}`)}>{jobQueueId}</Button>
                <IconButton sx={{flexShrink: 1}} onClick={() => {
                  const newjobQueueIds = jobQueueIds.filter((jq) => jq !== jobQueueId);
                  localStorage.setItem('jobQueueIds', JSON.stringify(newjobQueueIds));
                  navigate('/puppet');
                }}><CloseIcon/></IconButton>
              </Grid>
            ))
          }
        </Grid>
      </Container>
    </Box>
  );
}

import React from 'react';
import { Box, Button, Card, CardActions, CardOverflow, Checkbox, Divider, FormControl, FormLabel, IconButton, Input, LinearProgress, Modal, ModalClose, ModalDialog, Sheet, Stack, ToggleButtonGroup, Typography } from '@mui/joy';
import Grid from '@mui/joy/Grid';
import styled from 'styled-components';
import DiagnosticsView, { ChainNode } from './Diagnostics';
import { ChatInputPanel, darkTheme, DiagnosticPayload, DialogEvent, ErrorPayload, EventMode, FablecastServicePayload, MessagePane, ThemeProviderWrapper, useFablecast } from '@fablecast/components';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Delete from '@mui/icons-material/Delete';
import Table from '@mui/joy/Table';

export function FablecastLogin() {
  localStorage.setItem("fablecastIdentityToken", "")
  const queryString = window.location.hash;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('access_token') || urlParams.get("#access_token")
  if (token === null) {
    const errorDesc = urlParams.get('error_description');
    return (
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.level1',
          p: 4
        }}
      >
        <Card variant='outlined'>{errorDesc}</Card>
      </Sheet>
    );
  } else {
    localStorage.setItem("fablecastIdentityToken", token)
    window.close();
    return (<></>)
  }

}

const WindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  maxHeight: 100%;
  overflow-y: auto;
  min-height: calc(100vh - 191px);
  margin: 0 auto;
  padding: 0 auto;
  background-color: ${darkTheme.colors.background};
  color: ${darkTheme.colors.text};
  flex: 1;
  @media (min-width: 900px) {
    padding: ${darkTheme.spacing.lg};
    min-height: 0;
    width: 900px;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;


const MessageContainer = styled.div`
  flex: 1;
  margin-bottom: 50px; /* Space for input panel */
`;

export function MainWindow({fablecastEndpoint, setFablecastEndpoint, setNarrativeChain}: {
    fablecastEndpoint: string,
    setFablecastEndpoint: React.Dispatch<React.SetStateAction<string>>,
    setNarrativeChain: React.Dispatch<React.SetStateAction<ChainNode>>}) {
  const fablecast = useFablecast();
  const [narrativeId, setNarrativeId] = React.useState<string>(localStorage.getItem('narrativeId') || "");
  const [accessToken, setAccessToken] = React.useState<string>(localStorage.getItem('fablecastIdentityToken') || '');
  const [isConnecting, setIsConnecting] = React.useState<boolean>(false);
  const [narrativeVersion, setNarrativeVersion] = React.useState<string>(localStorage.getItem('narrativeVersion') || "");
  const [debug, setDebug] = React.useState<boolean>(localStorage.getItem('debugMode') === "true" || localStorage.getItem('debugMode') === null);
  const [promptOverrides, setPromptOverrides] = React.useState<Record<string, number>>(JSON.parse(localStorage.getItem('promptOverrides') || "{}"));
  const [promptOverrideName, setPromptOverrideName] = React.useState<string>("");
  const [promptOverrideVersion, setPromptOverrideVersion] = React.useState<string>("");
  const [continueMode, setContinueMode] = React.useState<boolean>(localStorage.getItem('continueMode') === "true");

  const handleLogin = async () => {
    const cognitoUri = 'fablecast.auth.eu-west-2.amazoncognito.com'
    const cognitoClientId = '7onsg7ggn50jsni31bosk2f2vv';
    const redirectUri = `${window.location.origin}/oauth2/idpresponse`;
    const idp = 'MindMageActiveDirectory'


    const cognitoLoginUrl = `https://${cognitoUri}/login?client_id=${cognitoClientId}&response_type=token&identity_provider=${idp}&scope=openid+email&redirect_uri=${encodeURIComponent(redirectUri)}`;
    const loginWindow = window.open(cognitoLoginUrl, '_blank', 'width=600,height=600');

    if (loginWindow) {
      const checkWindowClosed = setInterval(() => {
        if (loginWindow.closed) {
          clearInterval(checkWindowClosed);
          setAccessToken(localStorage.getItem('fablecastIdentityToken') || '');
        }
      }, 500);
    }
  };

  React.useEffect(() => {
    const handler = (payload: FablecastServicePayload) => {
      if (payload instanceof DiagnosticPayload) {
        setNarrativeChain(c => c.attachEvent(payload as DiagnosticPayload))
      }
    }
    var clientEventHandler: ((event: DialogEvent, parentId: string | undefined) => void) | undefined = undefined
    if (debug) {
      clientEventHandler = (event: DialogEvent, parentId: string | undefined) => {
        handler(new DiagnosticPayload(Date.now(), event, parentId, EventMode.ClientOnly, undefined, undefined))
      }
    }
    fablecast.controller.addPaylodListener(handler)
    if (debug) { fablecast.controller.addEventChainListener(clientEventHandler!) }
    return () => {
      fablecast.controller.clearPayloadListener(handler);
      if (debug) {fablecast.controller.clearEventChainListener(clientEventHandler!) }
    }
  }, [fablecast, setNarrativeChain, debug]);

  React.useEffect(() => {
    if (fablecastEndpoint === 'prod.api.fablecast.ai') {
      setDebug(false);
    }
  }, [fablecastEndpoint, setDebug])


  return fablecast.sessionId === undefined ? (
    <div style={{width: "100%", height: "100%", padding: "64px", alignContent: "center", alignItems: "center"}}>
        <Card sx={{maxWidth: "800px", marginLeft: "auto", marginRight: "auto"}}>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Start Fablecast Session</Typography>
            <Typography level="body-sm">
              Connecting to: {fablecastEndpoint}
            </Typography>
            <ToggleButtonGroup
              value={fablecastEndpoint}
              onChange={(event, newValue) => {
                setFablecastEndpoint(newValue!);
              }}
            >
              <Button value="localhost:8080">Local</Button>
              <Button value="test.api.fablecast.ai">Test</Button>
              <Button value="prod.api.fablecast.ai">Prod</Button>
            </ToggleButtonGroup>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>Narrative</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Input size="sm" placeholder="Narrative ID" value={narrativeId} onChange={(e) => {setNarrativeId(e.target.value)}}/>
                  <Input size="sm" placeholder="Narrative Version (Optional)" value={narrativeVersion} onChange={(e) => {setNarrativeVersion(e.target.value)}} sx={{ flexGrow: 1 }} />
                </FormControl>
              </Stack>
              <Stack spacing={1}>
                <FormLabel>Access Token</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Input size="sm" placeholder="Access Token" value={accessToken} onChange={(e) => {setAccessToken(e.target.value)}} 
                        startDecorator={<FingerprintIcon />}
                        endDecorator={<IconButton onClick={handleLogin}><VpnKeyIcon /></IconButton>}/>
                </FormControl>
              </Stack>
              <Stack spacing={1}>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Checkbox disabled={fablecastEndpoint === 'prod.api.fablecast.ai'} label="Debug Mode" checked={debug} onChange={(e) => setDebug(e.target.checked)}/>
                  <Checkbox label="Continue Session" checked={continueMode} onChange={(e) => setContinueMode(e.target.checked)}/>
                </FormControl>
              </Stack>
              { debug && (<Stack spacing={1}>
                <FormLabel>Prompt Overrides</FormLabel>
                <Table>
                <thead>
                  <tr>
                    <th>Prompt Name</th>
                    <th>Version</th>
                    <th style={{width: "64px"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(promptOverrides).map(e => (
                    <tr key={e[0]}>
                      <td>
                        {e[0]}
                      </td>
                      <td>
                        {e[1]}
                      </td>
                      <td>
                        <Button onClick={() => {setPromptOverrides(po => {
                          const newOut = {...po}
                          delete newOut[e[0]]
                          return newOut
                        })}}>
                          <Delete/>
                        </Button>
                      </td>
                    </tr>
                  ))}
                  
                  <tr>
                    <td>
                      <Input size="sm" placeholder="Prompt Name" value={promptOverrideName} onChange={(e) => {setPromptOverrideName(e.target.value)}}/>
                    </td>
                    <td>
                      <Input size="sm" placeholder="PromptVersion" value={promptOverrideVersion} onChange={(e) => {setPromptOverrideVersion(e.target.value)}}/>
                    </td>
                    <td>
                      <Button disabled={promptOverrideVersion === "" || promptOverrideName === "" || promptOverrideName in promptOverrides || isNaN(+promptOverrideVersion)} onClick={() => {
                        setPromptOverrides(po => {
                          const newOut = {...po}
                          newOut[promptOverrideName] = +promptOverrideVersion
                          return newOut
                        })
                        setPromptOverrideName("")
                        setPromptOverrideVersion("")
                      }}>
                        <AddCircleOutline/>
                      </Button>
                    </td>
                  </tr>
                </tbody>
                </Table>
              </Stack>) }
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            {isConnecting && <LinearProgress />}
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button size="sm" variant="solid" onClick={() => {
                  localStorage.setItem('narrativeId', narrativeId);
                  localStorage.setItem('continueMode', continueMode ? "true" : "false");
                  localStorage.setItem('debugMode', debug ? "true" : "false");
                  localStorage.setItem('narrativeVersion', narrativeVersion);
                  localStorage.setItem('promptOverrides', JSON.stringify(promptOverrides));
                  setIsConnecting(true);
                  fablecast.controller.connect(async () => {return accessToken}, narrativeId, narrativeVersion === "" ? undefined : narrativeVersion, continueMode, debug, promptOverrides, () => {setIsConnecting(false)});
              }}>
                Connect
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
    </div>
  ) : (<ThemeProviderWrapper>
    <WindowContainer>
      <ChatContainer>
        <MessageContainer>
          <MessagePane isFollowing={false} setIsFollowing={() => {}}/>
        </MessageContainer>
      </ChatContainer>
      <div style={{flexShrink: 1}}>
        <ChatInputPanel fixed={false}/>
      </div>
    </WindowContainer>
  </ThemeProviderWrapper>);
}

export default function Fablecast({fablecastEndpoint, setFablecastEndpoint}: {fablecastEndpoint: string, setFablecastEndpoint: React.Dispatch<React.SetStateAction<string>>}) {
  const [narrativeChain, setNarrativeChain] = React.useState<ChainNode>(new ChainNode());
  const [activeSession, setActiveSession] = React.useState<string>();
  
  const [currentDiagnostic, setCurrentDiagnostic] = React.useState<DiagnosticPayload>();
  const [error, setError] = React.useState<ErrorPayload>();
  
  const fablecast = useFablecast();

  React.useEffect(() => {
    if (activeSession !== fablecast.sessionId) {
      setActiveSession(fablecast.sessionId);
      setNarrativeChain(new ChainNode());
      setCurrentDiagnostic(undefined);
    }
  }, [fablecast.sessionId, setNarrativeChain, setActiveSession, activeSession, setCurrentDiagnostic]);

  return (<>
    {narrativeChain.children.length === 0 ? <MainWindow fablecastEndpoint={fablecastEndpoint} setFablecastEndpoint={setFablecastEndpoint} setNarrativeChain={setNarrativeChain}/> : (
      <Grid container spacing={2} sx={{ display: 'flex', height: '100%', p: 0, m: 0 }}>
        <Grid sx={{ flex: 2, height: '100%', overflow: 'hidden', p: 0, m: 0 }}>
          <MainWindow fablecastEndpoint={fablecastEndpoint} setFablecastEndpoint={setFablecastEndpoint} setNarrativeChain={setNarrativeChain}/>
        </Grid>
        <Grid sx={{ flex: 1, p: 4, m: 0,  height: '100%'}}>
          <DiagnosticsView diagnostic={currentDiagnostic} narrativeChain={narrativeChain} setCurrentDiagnostic={setCurrentDiagnostic} />
        </Grid>
      </Grid>
    )}

    <Modal open={error !== undefined} onClose={() => { setError(undefined) }}>
      <ModalDialog
        color="danger"
        layout="center"
        variant="solid"
      >
        <ModalClose />
        <Typography level={"h4"}>{error?.message}</Typography>
        {error?.stackTrace !== undefined && (
          <Sheet sx={{ p: 2, borderRadius: 8 }}>
            {error?.stackTrace?.map(t => (
              <pre style={{ margin: 0, padding: 0 }}>{t}</pre>
            ))}
          </Sheet>
        )}
      </ModalDialog>
    </Modal>
  </>
  );
}

import React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useLocation, useNavigate } from 'react-router-dom';
import CodeIcon from '@mui/icons-material/Code';

export default function Sidebar({langfuseUrl}: {langfuseUrl: string}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Avatar
          variant="outlined"
          size="sm"
          src={require('../logo512.png')}
        />
        <Typography level="title-md">MindMage Toolbox</Typography>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
         
          <ListItem>
            <ListItemButton selected={pathname === '/'} onClick={() => navigate('/')}>
              <AutoStoriesIcon />
              <ListItemContent>
                <Typography level="title-sm">Fablecast</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton selected={pathname === '/narratives'} onClick={() => navigate('/narratives')}>
              <DesignServicesIcon />
              <ListItemContent>
                <Typography level="title-sm">Narratives</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
          <ListItemButton selected={pathname === '/langfuse'} onClick={() => navigate('/langfuse')}>
              <CodeIcon />
              <ListItemContent>
                <Typography level="title-sm">LangFuse</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton selected={pathname === '/puppet'} onClick={() => navigate('/puppet')}>
              <EditNoteIcon />
              <ListItemContent>
                <Typography level="title-sm">Puppet</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

        </List>
      </Box>
    </Sheet>
  );
}

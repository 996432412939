import React from 'react';
import Sheet from '@mui/joy/Sheet';
import NarrativeListings from './NarrativesListings';
import NarrativeEditor from './NarrativeEditor';
import { NarrativeListing } from './NarrativeListing';

export type NarrativeEndpoints = {
  narrativeSearchEndpoint: string,
  narrativeCreateEndpoint: string,
  narrativeUpdateEndpoint: string,
  narrativePublishEndpoint: string,
  narrativeDeleteEndpoint: string,
  narrativeFetchEndpoint: string
}
export default function Narratives({narrativeEndpoints, userJwt}: {narrativeEndpoints: NarrativeEndpoints, userJwt: string}) {

  const [selectedListing, setSelectedListing] = React.useState<NarrativeListing>();
  
  return (<Sheet
    sx={{
      flex: 1,
      width: '100%',
      mx: 'auto',
      pt: { xs: 'var(--Header-height)', md: 0 },
      display: 'grid',
      gridTemplateColumns: {
        xs: '1fr',
        sm: 'minmax(min-content, min(30%, 400px)) 1fr',
      },
    }}
  >
    <Sheet
      sx={{
        position: { xs: 'fixed', sm: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
          sm: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 100,
        width: '100%',
        top: 52,
      }}
    >
      <NarrativeListings selectedListing={selectedListing} setSelectedListing={setSelectedListing} narrativeEndpoints={narrativeEndpoints} userJwt={userJwt}/>
    </Sheet>
    <NarrativeEditor selectedListing={selectedListing} setSelectedListing={setSelectedListing} narrativeEndpoints={narrativeEndpoints} userJwt={userJwt}/>
  </Sheet>);
}
